import React from "react";
import Layout from "~/components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import HubspotBrochureDownload from "~/components/HubspotBrochureDownload";

const ContactUs = () => {
	return (
		<Layout>
			<GatsbySeo
				title="Contact us"
				description="See the list of our guaranteed-to-depart bike tours in India and Asia."
				// languageAlternates={[
				// 	{
				// 		hrefLang: "en-IN",
				// 		href: "https://www.artofbicycletrips.in/upcoming-rides",
				// 	},
				// 	{
				// 		hrefLang: "en",
				// 		href: "https://www.artofbicycletrips.com/private",
				// 	},
				// ]}
			/>
			<section className="my-20">
				<h1 className="font-bold">Contact Us</h1>
				<h2 className="font-light text-primary max-w-3xl mb-14">
					We strive to provide a timely response, usually within one business day
				</h2>
				<p className="mb-10">
					Please fill out the form below to get started and one of our Cycling
					Experience Designers will reach out.
				</p>
				<h3 className="mb-10">Let's Start Planning!</h3>
				<div className="max-w-2xl">
					<HubspotBrochureDownload />
				</div>

				{/* <Helmet>
					<script src="https://static.airtable.com/js/embed/embed_snippet_v1.js"></script>
				</Helmet>
				<iframe
					class="airtable-embed airtable-dynamic-height"
					src="https://airtable.com/embed/shrJK2IBkNl1dL95K?backgroundColor=green"
					frameborder="0"
					onmousewheel=""
					width="100%"
					height="927"
					//style="background: transparent; border: 1px solid #ccc;"
				></iframe> */}

				{/* <div className="relative max-w-3xl pb-form">
					<iframe
						src="https://docs.google.com/forms/d/e/1FAIpQLSeSxbFT3aDSvcKcctYxEtGLX_yKt9kxX6JHgifpUDXx2HuhzQ/viewform?embedded=true"
						width="100%"
						height="100%"
						frameborder="0"
						marginheight="0"
						marginwidth="0"
						className="absolute w-full h-full"
					>
						Loading…
					</iframe>
					<
				</div> */}
				{/* <div className="max-w-xs sm:max-w-lg md:max-w-xl lg:max-w-2xl">
					<iframe
						src="https://docs.google.com/forms/d/e/1FAIpQLSeSxbFT3aDSvcKcctYxEtGLX_yKt9kxX6JHgifpUDXx2HuhzQ/viewform?embedded=true"
						width="100%"
						height="1250"
						frameborder="0"
						marginheight="0"
						marginwidth="0"
						//className="absolute w-full h-full"
					>
						Loading…
					</iframe>
				</div> */}

				{/* <PopupButton
					id="SKjDwiLC"
					className="bg-primary py-2 px-2 sm:px-4 rounded-full text-white mr-4"
				>
					Fill Form
				</PopupButton> */}
			</section>
		</Layout>
	);
};

export default ContactUs;
