import React from "react";

class HubspotBrochureDownload extends React.Component {
	componentDidMount(props) {
		const script = document.createElement("script");
		script.src = "https://js-eu1.hsforms.net/forms/shell.js";
		document.body.appendChild(script);

		script.addEventListener("load", () => {
			if (window.hbspt) {
				window.hbspt.forms.create({
					region: "eu1",
					portalId: "25780442",
					formId: "d421045f-ae00-4dcd-9450-37c4630f0351", //also you can do fromID: `${this.props.formID}`
					target: "#hubspotForm",
				});
			}
		});
	}

	render() {
		return (
			<div>
				<div id="hubspotForm"></div>
			</div>
		);
	}
}

export default HubspotBrochureDownload;
